<template>
    <div>
        <div class="row no-gutters justify-content-between align-items-center height-sm--8 editing-modal__header">
            <h2 class="editing-modal__title col-auto">
                {{ title }}
            </h2>
            <div 
                class="editing-modal__close col-1 d-flex justify-content-center" 
                @click="closeModal">
                <div class="icon icon--close"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EditingModalHeaderSimple',
    props: {
        title: {
            type: String,
            default: () => 'title',
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
    },
}
</script>
<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';
            .context-title {
            padding-left: 0px !important;
        }
    .editing-modal {
        &__title {
            margin-left: spacing(sm);
        }
    
        &__close {
            cursor: pointer;
            padding-top: spacing(sm);
            padding-bottom: spacing(sm);
        }


    }
</style>
