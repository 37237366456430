<template>
    <SelectInput
        v-model="parsedValue"
        :options="calcOptions"
        :label="label"
        :maxWidth="maxWidth"
    />
</template>

<script>
import moment from 'moment'
import SelectInput from 'components/cleverly/SelectInput'

export default {
    name: 'TimePicker',

    components: {
        SelectInput,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        label: String,
        maxWidth: String,
        prependOptions: Array
    },
    data() {
        return {
            options: [
                {value: '12:01 AM', text: '12:01 AM'},
                {value: '11:59 PM', text: '11:59 PM'},
                {value: '12:00 AM', text: '12:00 AM'},
                {value: '12:30 AM', text: '12:30 AM'},
                {value: '1:00 AM', text: '1:00 AM'},
                {value: '1:30 AM', text: '1:30 AM'},
                {value: '2:00 AM', text: '2:00 AM'},
                {value: '2:30 AM', text: '2:30 AM'},
                {value: '3:00 AM', text: '3:00 AM'},
                {value: '3:30 AM', text: '3:30 AM'},
                {value: '4:00 AM', text: '4:00 AM'},
                {value: '4:30 AM', text: '4:30 AM'},
                {value: '5:00 AM', text: '5:00 AM'},
                {value: '5:30 AM', text: '5:30 AM'},
                {value: '6:00 AM', text: '6:00 AM'},
                {value: '6:30 AM', text: '6:30 AM'},
                {value: '7:00 AM', text: '7:00 AM'},
                {value: '7:30 AM', text: '7:30 AM'},
                {value: '8:00 AM', text: '8:00 AM'},
                {value: '8:30 AM', text: '8:30 AM'},
                {value: '9:00 AM', text: '9:00 AM'},
                {value: '9:30 AM', text: '9:30 AM'},
                {value: '10:00 AM', text: '10:00 AM'},
                {value: '10:30 AM', text: '10:30 AM'},
                {value: '11:00 AM', text: '11:00 AM'},
                {value: '11:30 AM', text: '11:30 AM'},
                {value: '12:00 PM', text: '12:00 PM'},
                {value: '12:30 PM', text: '12:30 PM'},
                {value: '1:00 PM', text: '1:00 PM'},
                {value: '1:30 PM', text: '1:30 PM'},
                {value: '2:00 PM', text: '2:00 PM'},
                {value: '2:30 PM', text: '2:30 PM'},
                {value: '3:00 PM', text: '3:00 PM'},
                {value: '3:30 PM', text: '3:30 PM'},
                {value: '4:00 PM', text: '4:00 PM'},
                {value: '4:30 PM', text: '4:30 PM'},
                {value: '5:00 PM', text: '5:00 PM'},
                {value: '5:30 PM', text: '5:30 PM'},
                {value: '6:00 PM', text: '6:00 PM'},
                {value: '6:30 PM', text: '6:30 PM'},
                {value: '7:00 PM', text: '7:00 PM'},
                {value: '7:30 PM', text: '7:30 PM'},
                {value: '8:00 PM', text: '8:00 PM'},
                {value: '8:30 PM', text: '8:30 PM'},
                {value: '9:00 PM', text: '9:00 PM'},
                {value: '9:30 PM', text: '9:30 PM'},
                {value: '10:00 PM', text: '10:00 PM'},
                {value: '10:30 PM', text: '10:30 PM'},
                {value: '11:00 PM', text: '11:00 PM'},
                {value: '11:30 PM', text: '11:30 PM'},
            ],
        }
    },

    computed: {
        calcOptions() {
            if (this.prependOptions == undefined) {
                return this.options
            }
            else return this.prependOptions.concat(this.options)
        },
        parsedValue: {
            get() {
                if (this.simple) {
                    return this.value
                }

                return moment.utc(this.value).format('h:mm A')
            },
            set(value) {
                const newVal = this.simple ?
                    value :
                    moment.utc(value, 'h:mm A').format('[/Date(]xZZ[))/]')

                this.$emit('input', newVal)
            },
        },
        selectStyle() {
            if (this.maxWidth == undefined) return {}
            return {
                maxWidth: this.maxWidth
            }
        }
    },
}
</script>

