var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectInput", {
    attrs: {
      options: _vm.calcOptions,
      label: _vm.label,
      maxWidth: _vm.maxWidth,
    },
    model: {
      value: _vm.parsedValue,
      callback: function ($$v) {
        _vm.parsedValue = $$v
      },
      expression: "parsedValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }