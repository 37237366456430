var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "row no-gutters justify-content-between align-items-center height-sm--8 editing-modal__header",
      },
      [
        _c("h2", { staticClass: "editing-modal__title col-auto" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c(
          "div",
          {
            staticClass:
              "editing-modal__close col-1 d-flex justify-content-center",
            on: { click: _vm.closeModal },
          },
          [_c("div", { staticClass: "icon icon--close" })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }